@import "./Mixins.scss";

.modalWrapper {
  @include position(fixed, $top: 0px, $left: 0px);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 91;
  background: rgba(#000000, 0.4);
  // backdrop-filter: blur(4px);
  // display: flex;
  // justify-content: center;
  // align-items: center;

  .modalContent {
    background: $white;
    @include border-radius(24px);
    @include box-shadow(0px 6px 12px rgba($black, 0.2));
    font-family: "jioType";
    margin: 40px auto;
    max-width: 764px;
    width: 100%;
    padding: 12px 24px 32px !important;
    position: relative;
    transition: all 0.5s ease-in-out;

    .eInsuranceContent {
      h3 {
        margin-bottom: 32px;
        font-size: 32px;
        font-style: normal;
        line-height: 32px;
        letter-spacing: -0.96px !important;
        @media (max-width: 600px) {
          margin-bottom: 16px;
          font-size: 28px;
        }
      }
      p {
        padding: 40px 0;
        @media (max-width: 600px) {
          padding: 24px 0;
        }
      }
    }

    @media only screen and (max-width: 899px) {
      padding: 12px 24px 24px !important;
    }

    &:focus-visible {
      outline: none;
    }

    &.xlWidth {
      max-width: 981px;
    }

    &.smWidth {
      max-width: 584px;
    }

    &.xsWidth {
      max-width: 384px;
    }

    .closePopup {
      @include border-radius(40px);
      position: relative;
      min-width: 20px;
      padding: 0px;
      height: 48px;
      width: 48px;

      &:before {
        background: $black;
        content: "";
        @include position(absolute, $left: 24px, $top: 12px);
        transform: rotate(45deg);
        height: 24px;
        width: 2px;
        transition: transform 0.2s ease-in-out;
        border-radius: 50px;
      }

      &:after {
        @extend :before;
        transform: rotate(-45deg);
      }

      &:hover {
        &:before {
          transform: rotate(90deg);
          background: $primary;
        }

        &:after {
          transform: rotate(-90deg);
          background: $primary;
        }
      }
    }

    .topbar {
      margin: 0 -12px 0px -12px;
    }

    .title {
      margin-bottom: 16px;

      @media (max-width: 900px) {
        @include fontSize(24px);
        line-height: 28px;
      }
    }

    .mlineHeight {
      @media only screen and (max-width: 899px) {
        line-height: 23px;
      }
    }

    hr {
      border: none;
      border-top: 1px solid #e0e0e0;
    }

    .policy-addons {
      display: flex;
      gap: 12px;

      li {
        flex: 1;
        @include fontSize(12px);
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.06px;
        position: relative;
        padding-left: 19px;

        &:before {
          @include position(absolute, $top: 2px, $left: 0);
          content: "";
          // background: url("./../../public/images/policy-addon-tick.svg")
          // no-repeat 0 0/100%;
          width: 12px;
          height: 13px;
        }
      }
    }

    .tc_checkbox {
      .MuiFormControlLabel-root {
        margin-right: 0;
        margin: auto;
        justify-content: center;
      }
    }

    .edit_p {
      position: relative;
      @include fontSize(15px);

      .edit {
        @include position(absolute, $top: 0px, $right: 2px);
        content: "";
        // background: url("./../../public/images/ic_edit_pen.svg") no-repeat 0 0/24px;
        height: 24px;
        width: 24px;
        display: inline-block;
      }
    }

    .investmentInputBox {
      input[type="number"] {
        -moz-appearance: textfield;
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .otpFieldBox {
      display: flex;
      gap: 12px;
      justify-content: center;
      margin-bottom: 24px;

      input {
        width: 40px;
        height: 35px;
        text-align: center;
        @include fontSize(16px);
        font-weight: 500;
        line-height: 24px;
        border: none;
        outline: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.65);
        font-family: "JioType";

        &::placeholder {
          color: #b5b5b5;
        }
      }
    }
  }
}

.modal-scrollable {
  background: rgba($color: #000000, $alpha: 0.8);
  // backdrop-filter: blur(4px);
  z-index: 9999;

  .MuiModal-backdrop {
    background: transparent;
  }

  &.responsive {
    .MuiPaper-root {
      @media (max-width: 767px) {
        padding: 24px 0px 0px !important;
        border-radius: 0px !important;
        vertical-align: bottom;
      }
    }
    .modal-content {
      h4 {
        @include fontSize(23px);
        font-weight: 900;
      }
      @media (max-width: 767px) {
        border-radius: 24px 24px 0px 0px !important;
        padding: 24px;
        h4 {
          @include fontSize(24px);
        }
      }
    }
  }

  .MuiPaper-root {
    max-width: none;
    width: 100%;
    background: none;
    box-shadow: none;
    // @include border-radius(24px);
    margin: 0px;
    padding: 24px 12px;

    @media (max-width: 600px) {
      max-width: none !important;
    }

    .modal-content {
      font-family: "jioType";
      max-width: 764px;
      width: 100% !important;
      padding: 12px 24px 32px;
      background: $white;
      margin: auto;
      @include border-radius(24px);

      &.scheduleCallPopup {
        .MuiDateCalendar-root {
          height: auto !important;

          .MuiPickersCalendarHeader-root {
            margin-top: 0px;
            padding-left: 0px;
            padding-right: 0px;
          }

          .MuiDayCalendar-header {
            justify-content: space-between;
          }

          .MuiDayCalendar-weekContainer {
            justify-content: space-between;

            button {
              &.Mui-selected {
                background-color: $primary;
                color: $black;
              }
            }
          }

          .MuiDayCalendar-weekContainer {
            button {
              &.Mui-disabled {
                display: none;
              }
            }
          }

          .MuiPickersSlideTransition-root {
            min-height: 200px;
          }
        }

        .date-selector {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          text-align: center;
        }

        .date-item {
          text-align: center;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          height: 32px;
          width: 32px;
        }

        .date-item.selected {
          background-color: $primary;
          color: $black !important;
          border-radius: 50%;
        }

        .time-selector {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 16px;

          .time-slot {
            padding: 12px;
            text-align: center;
            border: 1px solid $primary;
            color: $primary;
            border-radius: 80px;
            cursor: pointer;
            font-weight: bold;

            &.selected {
              background-color: $primary;
              color: $black;
            }
          }
        }
      }

      &.xlWidth {
        max-width: 981px;
      }
      &.lgWidth {
        max-width: 768px;
      }

      &.smWidth {
        max-width: 584px;
      }

      &.xsWidth {
        max-width: 384px;
      }

      .backLink {
        position: relative;
        min-width: 20px;
        padding: 0px;
        height: 34px;
        width: 34px;

        &:before {
          background: $darkgold;
          content: "";
          @include position(absolute, $left: 14px, $top: 7px);
          transform: rotate(45deg);
          height: 12px;
          width: 2px;
          transition: transform 0.2s ease-in-out;
          border-radius: 50px;
        }

        &:after {
          @extend :before;
          top: 15px;
          transform: rotate(-45deg);
        }
      }
      .closePopup {
        @include border-radius(40px);
        position: relative;
        min-width: 20px;
        padding: 0px;
        height: 34px;
        width: 34px;

        &:before {
          background: $black;
          content: "";
          @include position(absolute, $left: 16px, $top: 8px);
          transform: rotate(45deg);
          height: 18px;
          width: 3px;
          transition: transform 0.2s ease-in-out;
          border-radius: 50px;
        }

        &:after {
          @extend :before;
          transform: rotate(-45deg);
        }

        &:hover {
          &:before {
            transform: rotate(90deg);
            background: $primary;
          }

          &:after {
            transform: rotate(-90deg);
            background: $primary;
          }
        }
      }

      .topbar {
        margin: 0 -12px 12px -12px;
      }

      hr {
        border: none;
        border-top: 1px solid #e0e0e0;
      }

      .policy-addons {
        display: flex;
        gap: 12px;

        li {
          flex: 1;
          @include fontSize(12px);
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.06px;
          position: relative;
          padding-left: 19px;

          &:before {
            @include position(absolute, $top: 2px, $left: 0);
            content: "";
            // background: url("./../../public/images/policy-addon-tick.svg")
            //   no-repeat 0 0/100%;
            width: 12px;
            height: 13px;
          }
        }
      }

      .tc_checkbox {
        .MuiFormControlLabel-root {
          margin-right: 0;
          margin: auto;
          justify-content: center;
        }
      }

      .edit_p {
        position: relative;

        .edit {
          @include position(absolute, $top: -2px, $right: 0);
          content: "";
          // background: url("./../../public/images/ic_edit_pen.svg") no-repeat 0 0/24px;
          height: 24px;
          width: 24px;
          display: inline-block;
        }
      }

      .otpFieldBox {
        display: flex;
        gap: 12px;
        justify-content: center;
        margin-bottom: 24px;

        input {
          width: 40px;
          height: 35px;
          text-align: center;
          @include fontSize(16px);
          font-weight: 500;
          line-height: 24px;
          border: none;
          outline: none;
          border-bottom: 2px solid rgba(0, 0, 0, 0.65);
          font-family: "JioType";

          &::placeholder {
            color: #b5b5b5;
          }
        }
      }
    }
  }
}

.modalWrapperMobile {
  background: $white;
  @include position(fixed, $top: 0px, $left: 0px);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 99;

  .modalContent {
    background: $white;
    width: 100%;

    .primaryBtn {
      .MuiButton-root {
        background-color: $primary;
        border: none;
        color: $warmgold;
        border-radius: 100px;
        font-weight: bold;
        letter-spacing: -0.08px;
        &.MuiButton-sizeLarge {
          @include fontSize(18px);
          padding: 16px 24px;
          height: 48px;
        }
        &.MuiButton-sizeMedium {
          height: 40px;
        }
        &.MuiButton-sizeSmall {
          padding: 4px 12px;
          @include fontSize(12px);
          height: 32px;
        }
        svg {
          fill: $warmgold;
        }
        &:hover {
          box-shadow: none;
          background-color: #fce0bd !important;
          color: #644920 !important;

          svg {
            fill: #644920;
          }
        }
        &:active {
          box-shadow: none;
          background-color: $darkgold;
        }
      }
    }
    .secondaryBtn {
      .MuiButton-root {
        color: $darkgold;
        border: none;
        outline: 1px solid #b5b5b5;
        border-radius: 100px;
        font-family: "JioType";
        letter-spacing: -0.08px;
        font-weight: bold;
        &.MuiButton-sizeLarge {
          @include fontSize(18px);
          padding: 16px 24px;
          height: 48px;
        }
        &.MuiButton-sizeMedium {
          height: 40px;
        }
        &.MuiButton-sizeSmall {
          padding: 4px 12px;
          @include fontSize(12px);
          height: 32px;
        }
        &:hover {
          box-shadow: none;
          background-color: #fce0bd !important;
          color: $darkgold !important;
        }
      }
    }
    .tertiaryBtn {
      .MuiButton-root {
        color: $darkgold;
        letter-spacing: -0.08px;
        font-weight: bold;
        &.MuiButton-sizeSmall {
          padding: 4px 12px;
          @include fontSize(12px);
          height: 32px;
        }
        &:hover {
          box-shadow: none;
          background-color: transparent !important;
          color: $darkgold !important;
        }
      }
    }

    .evInputField {
      .MuiInputLabel-root {
        font-family: "JioType" !important;
        @include fontSize(16px);
        transform: translate(0px, 10px) scale(1);
        &.MuiInputLabel-shrink {
          transform: translate(0px, -10px) scale(0.8);
          color: $lightgrey;
        }
      }
      .MuiInputBase-root {
        background: transparent;
        font-family: "JioType" !important;
        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-bottom: 2px solid $darkgold;
          }
        }
        .MuiInputBase-input {
          padding-left: 0px;
          font-weight: 500;
          @include fontSize(16px);
          letter-spacing: 0px;
          font-family: "JioType" !important;
        }
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-bottom: 2px solid #df9f39;
          }
        }
        &:focus {
          .MuiOutlinedInput-notchedOutline {
            border-bottom: 2px solid $darkgold;
          }
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-radius: 0px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid $lightgrey;
      }
    }

    &:focus-visible {
      outline: none;
    }

    .topbar {
      @include position(fixed, $top: 0, $left: 0);
      height: 72px;
      background: $white;
      width: 100%;
      padding: 12px;
      z-index: 9;

      .closePopup {
        @include border-radius(40px);
        position: relative;
        min-width: 20px;
        padding: 0px;
        height: 48px;
        width: 48px;

        &:before {
          background: $black;
          content: "";
          @include position(absolute, $left: 24px, $top: 12px);
          transform: rotate(45deg);
          height: 24px;
          width: 2px;
          transition: transform 0.2s ease-in-out;
          border-radius: 50px;
        }

        &:after {
          @extend :before;
          transform: rotate(-45deg);
        }

        &:hover {
          &:before {
            transform: rotate(90deg);
            background: $primary;
          }

          &:after {
            transform: rotate(-90deg);
            background: $primary;
          }
        }
      }
    }

    .scrollable-area {
      padding: 72px 12px 96px;
    }

    .footerbar {
      @include position(fixed, $bottom: 0, $left: 0);
      box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 0.08);
      // height: 96px;
      background: $white;
      width: 100%;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      z-index: 99;
    }
  }
}

.modalFormsMobile {
  background: $white;
  @include position(fixed, $top: 0px, $left: 0px);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 99;

  .modalContent {
    background: $white;
    width: 100%;

    &:focus-visible {
      outline: none;
    }

    .topbar {
      @include position(fixed, $top: 0, $left: 0);
      height: 64px;
      background: $lightMarigold20;
      width: 100%;
      padding: 12px;
      z-index: 9;
      display: flex;
      align-items: center;
    }

    .scrollable-area {
      padding: 80px 12px 24px;
      &.sa-md {
        padding-bottom: 80px !important;
      }
    }

    .footerbar {
      @include position(fixed, $bottom: 0, $left: 0);
      height: 96px;
      background: $white;
      width: 100%;
      padding: 12px 24px;
      z-index: 9;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // gap: 16px;
    }
  }
}

.popupBgOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  @include position(fixed, $left: 0px, $top: 0px);
  height: 100%;
  width: 100%;
  z-index: 91;

  .contentWrapper {
    background: $white;
    @include position(absolute, $left: 0px, $bottom: 0px);
    animation: slideUp 1s forwards;
    width: 100%;
    opacity: 0.6;
    border-radius: 24px 24px 0 0;
    overflow: hidden;

    @keyframes slideUp {
      from {
        transform: translate3d(0, 80px, 0);
      }

      to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }

    .topbar {
      @include position(fixed, $top: 0, $left: 0);
      height: 72px;
      background: $white;
      width: 100%;
      padding: 12px;
      z-index: 9;

      .closePopup {
        @include border-radius(40px);
        position: relative;
        min-width: 20px;
        padding: 0px;
        height: 48px;
        width: 48px;

        &:before {
          background: $black;
          content: "";
          @include position(absolute, $left: 24px, $top: 12px);
          transform: rotate(45deg);
          height: 24px;
          width: 2px;
          transition: transform 0.2s ease-in-out;
          border-radius: 50px;
        }

        &:after {
          @extend :before;
          transform: rotate(-45deg);
        }

        &:hover {
          &:before {
            transform: rotate(90deg);
            background: $primary;
          }

          &:after {
            transform: rotate(-90deg);
            background: $primary;
          }
        }
      }
    }

    .scrollable-area {
      padding: 72px 12px 32px;
    }

    // .footerbar {
    //   box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 0.08);
    //   height: 96px;
    //   background: $white;
    //   width: 100%;
    //   padding: 12px 24px;
    //   display: flex;
    //   align-items: center;
    // }

    hr {
      border: none;
      border-top: 1px solid #e0e0e0;
    }

    .comparebox {
      background: $lightbg;
      padding: 8px;
      border-radius: 8px;
      width: calc(50% - 4px);
      position: relative;

      .crossIcon {
        @include position(absolute, $top: -4px, $right: -2px);
        display: inline-block;
        z-index: 9;

        .cross {
          width: 20px;
          height: 20px;
        }
      }

      img {
        width: 100px;
        height: 50px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: $darkgrey;
        margin-top: 8px;
      }
    }

    .policy-addons {
      display: flex;
      flex-direction: column;
      gap: 8px;

      li {
        flex: 1;
        @include fontSize(12px);
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.06px;
        position: relative;
        padding-left: 19px;

        &:before {
          @include position(absolute, $top: 2px, $left: 0);
          content: "";
          // background: url("./../../public/images/policy-addon-tick.svg")
          //   no-repeat 0 0/100%;
          width: 12px;
          height: 13px;
        }
      }
    }

    .otpFieldBox {
      display: flex;
      gap: 12px;
      justify-content: space-between;
      margin-bottom: 24px;

      input {
        width: 40px;
        height: 35px;
        text-align: center;
        @include fontSize(16px);
        font-weight: 500;
        line-height: 24px;
        border: none;
        outline: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.65);
        font-family: "JioType";

        &::placeholder {
          color: #b5b5b5;
        }
      }
    }

    .edit_p {
      position: relative;

      .edit {
        @include position(absolute, $top: -2px, $right: 0);
        content: "";
        // background: url("./../../public/images/ic_edit_pen.svg") no-repeat 0 0/24px;
        height: 24px;
        width: 24px;
        display: inline-block;
      }
    }
  }
}
